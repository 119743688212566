<template>
  <section class="intro">
    <div class="container">
      <div class="row intro__row">
        <div class="col-12">
            <h1 class="h404">404 😔</h1>
        </div>
      </div>
    </div>
  </section>
</template>


<script>
import rocketImage from 'images/rocket.png';

export default {
  name: "Index",
  metaInfo() {
    return {
    title: "404 page not found",
    meta: [
        { name: 'description', content: this.$t("translation.hello_text") },
    ],
    }
  },
  data() {
    return {
      rocketImage
    };
  }
};
</script>
