<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="admin-login">
          <form @submit="login" class="contacts__form">
            <div v-if="error"  class="alert alert-danger" role="alert">
              Ошибка!
            </div>
            <div class="form-group">
              <label>mail</label>
              <input type="text" class="form-control" v-model="email" name="email" />
            </div>
            <div class="form-group">
              <label>pwd</label>
              <input type="text" class="form-control" v-model="password" name="password" />
            </div>
            <input type="submit" class="submit-button" value="login" />
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Auth } from '../Auth.js';

export default {
  name: "Login",
  data() {
    return {
      email: '',
      password: '',
      error: false
    };
  },
  created () {
    //this.checkSignedIn()
  },
  updated () {
    //this.checkSignedIn()
  },
  methods: {
    login: function (e) {
      e.preventDefault();
      Auth.CreateSession(this.email, this.password).then(
        response => {
          if (response.status === 201) {
            Auth.SetCurrentUser(
              response.data.auth_token,
              response.data.user
            );
            this.$router.push('/');
          } else {
            console.log(response);
          }
        }
      );
      
    },
  }
};
</script>
