<template>
  <div class="container works">
    <div class="row">
      <div class="col-12">
        <h1 class="page-header">
          {{$t("translation.header_works")}}
        </h1>
        <ul class="horizontal-menu clearfix" v-if="categories">
          <li v-for="category in categories" :key="category.id">
            <a class="catselector" v-bind:class="{ active: category.id==activeIndex }" :data-category="category.id" @click="changeCategory" href="javascript:void(0)">
              {{category[row_name]}}
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-12 thumbs-container" v-if="worksAreLoaded">
        <div class="thumb" :data-category="work.category_id" v-for="work in works" :key="work.id" v-bind:style="{ 'background-image': 'url(' +work.thumb_img.url + ')' }">
          <a class="thumb__link" :href="$func.getLink('works/'+ work.id)">
            <div class="hidden-info" v-bind:style="{'background-color': work.background_color, 'color': work.header_color}">
              <h4>{{work[row_name]}}</h4>
              <div class="thumb__description">{{work[row_description]}}</div>
              <div class="thumb__button" v-bind:style="{'color': work.header_color}">{{$t("translation.button_explore")}}</div>
            </div>
          </a>
        </div>
      </div>
      <div class="col-12 thumbs-container" v-else>
        <div class="loading-animation">
          <img v-bind:src="spinnerAnimation">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import spinnerAnimation from 'images/spinner.svg';

export default {
  name: 'Works',
  data () {
    return {
      spinnerAnimation,
      categories: [{name_en: "All project", name_ru: "Все работы", id: 0}],
      activeIndex: 0,
      all_works: [],
      works: [],
      worksAreLoaded: false
    }
  },
  metaInfo() {
    return {
    title: this.$t("translation.title_works"),
    meta: [
        { name: 'description', content: this.$t("translation.meta_works") },
    ],
    }
  },
  computed: {
    row_name: function () {return "name_" + this.$root.$i18n.locale},
    row_description: function () {return "description_" + this.$root.$i18n.locale}
  },
  mounted() {
    axios
      .get("https://velichko.co/api/categories")
      .then((response) => {
        this.categories = [...this.categories, ...response.data];
        //console.log("CATEGORIES", this.categories);
      })
      .catch((error) => this.setError(error, "Something went wrong"));
    axios
      .get('https://velichko.co/api/works.json')
      .then(response => {
        this.all_works = response.data;
        this.works = response.data;
        this.worksAreLoaded = true;
        //console.log("hi freaks", response.data);
      });
    console.log(this.all_works);
  },
  methods: {
    changeCategory: function(e) {
      e.preventDefault();
      let category_id = event.currentTarget.getAttribute('data-category');
      this.activeIndex = category_id;
      if (category_id==0) this.works = this.all_works;
      else {
        this.works = this.all_works.filter(obj => {
            return obj.category_id == category_id
        });
      }
    }
    
  }
}
</script>
