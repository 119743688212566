import Vue from 'vue';
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);

const messages = {
    en: {
      translation: {
        title_index: 'Alexander Velichko, UI/UX designer and front-end developer',
        title_works: 'My portfolio | Alexander Velichko, UI/UX designer and front-end developer',
        meta_works: 'Some projects of Alexander Velichko',
        meta_contacts: 'Do you want me to design your next project, or just got any questions? Feel free to contact me.',
        title_contacts: 'My contacts | Alexander Velichko, UI/UX designer and front-end developer',
        title_services: 'My services | Alexander Velichko, UI/UX designer and front-end developer',
        title_policy: 'Privacy Policy | Alexander Velichko',
        meta_services: 'I provide a wide range of services such as UI/UX design, front-end development and graphic design. Learn more',
        name: 'Alexander Velichko',
        hello_header: 'UI/UX & webdev',
        hello_text: 'Hello there, I’m Alexander. I’m a designer and front-end developer with more than six years of experience. Here you can check out my works and hire me.',
        menu_contacts: 'Contacts',
        menu_works: 'Works',
        menu_services: 'Services',
        header_contacts: 'My contacts',
        header_works: 'My works',
        header_services: 'My services',
        button_works: 'Explore works',
        button_contacts: 'Contact me',
        works_all: 'All works',
        button_explore: 'Explore',
        form_name: 'Your name',
        form_mail: 'Your email',
        form_text: 'Your question or problem',
        form_description_1: 'The easiest way to contact me is to fill and submit this form.',
        form_description_2: 'I usually reply within 1-2 working hours (I live in GMT+6 time zone).',
        form_description_3: 'Also you can email me at',
        form_errors: 'Please fill these inputs correctly: ',
        form_success: 'Thank you! Your message was successfully submitted.',
        form_error_name: 'Your name',
        form_error_mail: 'Your email',
        form_error_text: 'Text of your message',
        form_button: 'Send',
        services_description_1: 'I provide a wide range of services in the fields of web development and design.',
        services_description_2: 'Feel free to ask me anything regarding my services or prices.',
        services_the_main_service: 'the main service',
        service_1_header: 'UI/UX',
        service_1_description_1: 'I have six years of experience (both as a full-time employee and as a freelancer) and have completed more than 50 projects. These included landing pages, business sites, content projects and online shops.',
        service_1_description_2: 'I know how to present your product and then sell it to your potential customers.',
        service_2_header: 'Front-end',
        service_2_description: "I can develop front-end for almost any framework or content management system. Static, Drupal, WordPress, Ghost, RoR… you name it. My stack is HTML5, HAML, CSS3, Less, Sass, Javascript, jQuery, Vue.js, React.",
        service_3_header: 'Graphic design',
        service_3_description: "Even though it's not my main service, I am also familiar with graphic design. I design logos, illustrations, banners, booklets, and other promotional materials when necessary.",
        service_4_header: 'Support & maintenance',
        service_4_description: 'I support my clients even after the project is done. You may need to set up backups, design banners, or just make some cosmetic changes on your website. I am always glad to help.',
        service_4_questions: 'Got any questions? You can contact me with',
        service_4_questions_form: 'this form',
        service_4_questions_telegram: 'telegram',
        service_4_questions_or: 'or',
        service_4_questions_email: 'email',
        privacy_policy_text: 'By clicking submit you agree to the',
        privacy_policy_link: 'Privacy Policy'
      }
    },
    ru: {
      translation: {
        title_index: 'Александр Величко, UI/UX дизайнер и веб-программист',
        title_works: 'Портфолио | Александр Величко, UI/UX дизайнер и веб-программист',
        meta_works: 'Работы Александра Величко, веб-дизайнера и программиста',
        title_contacts: 'Мои контакты | Александр Величко, UI/UX дизайнер и веб-программист',
        meta_contacts: 'Хотите заказать дизайн, верстку или все сразу? Заполните форму и я свяжусь с вами в течение 1-2 часов',
        title_services: 'Мои услуги | Александр Величко, UI/UX дизайнер и веб-программист',
        meta_services: 'Я оказываю широкий спектр услуг, в том числе веб-дизайн, создание сайтов и графический дизайн. Узнайте больше на этой странице',
        title_policy: 'Обработка персональных данных | Александр Величко',
        name: 'Александр Величко',
        hello_header: 'UI/UX и веб-дизайн',
        hello_text: 'Привет! Меня зовут Александр, я дизайнер и веб-программист. Здесь можно ознакомиться с моими работами и связаться со мной.',
        menu_contacts: 'Контакты',
        menu_works: 'Работы',
        menu_services: 'Услуги',
        header_contacts: 'Связаться со мной',
        header_works: 'Мои работы',
        header_services: 'Мои услуги',
        button_works: 'Мое портфолио',
        button_contacts: 'Написать мне',
        works_all: 'Все работы',
        button_explore: 'Перейти',
        form_name: 'Ваше имя',
        form_mail: 'Электронная почта',
        form_text: 'Ваш вопрос или проблема',
        form_description_1: 'Самый простой способ связаться со мной – заполнить эту форму.',
        form_description_2: 'Я отвечаю в течение пары рабочих часов (+3 часа от Москвы).',
        form_description_3: 'Вы также можете написать мне в Телеграм или на почту ',
        form_errors: 'Пожалуйста, заполните следующие поля правильно:',
        form_success: 'Спасибо! Ваше сообщение отправлено.',
        form_error_name: 'Укажите имя',
        form_error_mail: 'Укажите адрес почты',
        form_error_text: 'Опишите вашу проблему или вопрос',
        form_button: 'Отправить',
        services_description_1: 'Я оказываю довольно широкий спектр услуг, связанных с веб-сайтами и сервисами.',
        services_description_2: 'Цена моей работы рассчитывается исходя из предполагаемого объема задачи.',
        services_the_main_service: 'главная услуга',
        service_1_header: 'Веб-дизайн',
        service_1_description_1: 'За 6 лет работы дизайнером (во фрилансе и различных командах) я выполнил свыше 50 проектов. В их число входили продающие лендинги, бизнес-сайты, контентные проекты и интернет-магазины.',
        service_1_description_2: 'Я всегда ориентируюсь на цели клиента и специфику проекта; слежу за эргономикой и удобством.',
        service_2_header: 'Верстка и фронт-энд',
        service_2_description: 'Я верстаю статику, натягиваю код на CMS и программирую фронт-энд (и монолит, и SPA). Стак: HTML5, HAML, CSS3, Less, Sass, Javascript, jQuery, Vue.js, React и другое.',
        service_3_header: 'Графический дизайн',
        service_3_description: 'Хоть это и не является моей основной услугой, при необходимости я рисую иллюстрации, баннеры, визитки, брошюры и другие рекламные материалы.',
        service_4_header: 'Поддержка и исправление проблем',
        service_4_description: 'Довольно часто работа с сайтом не заканчивается на его публикации в сети. Вам может понадобиться добавить функционал, настроить резервное копирование, нарисовать баннеры для рекламы или просто внести какие-то косметические изменения. При необходимости я помогаю и с этим.',
        service_4_questions: 'Остались вопросы? Просто свяжитесь со мной через',
        service_4_questions_form: 'эту форму',
        service_4_questions_telegram: 'Телеграм',
        service_4_questions_or: 'или',
        service_4_questions_email: 'почту',
        privacy_policy_text: 'Отправляя эту форму, вы соглашаетесь',
        privacy_policy_link: 'с Политикой обработки данных'
      }
    }
  }

  export default new VueI18n ({
    silentTranslationWarn: true,
    fallbackLocale: 'en',
    messages
  });