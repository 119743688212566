<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="admin-row">
          <h1>Админка</h1>
        </div>
        <div class="admin-row">
    <div class="header-group">
      <h3>Обратная связь</h3>
    </div>
    <div class="row-content">
      <table class="table table-bordered table-hover admin-table">
        <tbody>
          <tr v-for="contact in contacts" :key="contact.id">
            <td>
                <a :href="'/admin/contacts/' + contact.id">{{ contact.name }}</a>
            </td>
            <td> {{contact.mail}}</td>
            <td>{{ contact.created_at }}</td>
            <td>
              <a href="javascript:void(0)" @click="removeItem(contact.id, 'contacts')" class="link-black">
                  <svg class="bi bi-trash" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                  <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/></svg>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div></div>
        <div class="admin-row">
          <div class="header-group">
            <h3>Работы</h3>
            <a
              v-if="!workFormActive"
              @click="workFormActive = !workFormActive"
              href="#"
              class="btn btn-primary btn-sm"
              >+</a
            >
          </div>
          <form
            @submit="workFormSubmit"
            class="new-form new-form_works"
            v-if="workFormActive"
          >
            <div class="header-group">
              <h4>Новая работа</h4>
              <button @click="workFormActive = !workFormActive"  type="button" class="btn btn-secondary btn-sm">x</button>
            </div>
            <div class="flexj">
              <div class="form-group">
                <label>Название работы на русском</label>
                <input type="text" class="form-control" v-model="work.name_ru" />
              </div>
              <div class="form-group">
                <label>Название работы на английском</label>
                <input type="text" class="form-control" v-model="work.name_en" />
              </div>
            </div>
            <div class="flexj">
              <div class="form-group">
                <label>Описание на русском</label>
                <textarea
                  type="text"
                  class="form-control"
                  v-model="work.description_ru"
                ></textarea>
              </div>
              <div class="form-group">
                <label>Описание на английском</label>
                <textarea
                  type="text"
                  class="form-control"
                  v-model="work.description_en"
                ></textarea>
              </div>
            </div>
            <div class="form-group">
              <label>Категория</label>
              <select
                id="artist_update"
                class="form-control"
                v-model="work.category_id"
              >
                <option
                  :value="category.id"
                  v-for="(category, index) in categories"
                  :key="category.id"
                  v-bind:selected="index === 0"
                  >{{ category.name_ru }}</option
                >
              </select>
            </div>
            <div class="form-group">
              <label>Ссылка</label>
              <input type="text" class="form-control" v-model="work.link" />
            </div>
            <div class="flexj">
              <div class="form-group">
              <label>Фон</label>
              <input
                type="file"
                class="form-control admin-work-file"
                accept="image/*"
                name="background_img"
                @change="handleImage"
              />
            </div>
            <div class="form-group">
              <label>Картинка</label>
              <input
                type="file"
                class="form-control admin-work-file"
                accept="image/*"
                name="main_img"
                @change="handleImage"
              />
            </div>
            <div class="form-group">
              <label>Миниатюра</label>
              <input
                type="file"
                class="form-control admin-work-file"
                accept="image/*"
                name="thumb_img"
                @change="handleImage"
              />
            </div>
            </div>
            <div class="flexj">
              <div class="form-group">
                <label>Цвет фона</label>
                <input
                  type="color"
                  class="form-control"
                  v-model="work.background_color"
                />
              </div>
              <div class="form-group">
                <label>Цвет заголовка</label>
                <input
                  type="color"
                  class="form-control"
                  v-model="work.header_color"
                />
              </div>
              <div class="form-group">
                <label>Цвет подзаголовка</label>
                <input
                  type="color"
                  class="form-control"
                  v-model="work.description_color"
                />
              </div>
            </div>
            <div class="btn-group" role="group" aria-label="Basic example">
              <input
                type="submit"
                class="btn btn-primary btn-sm"
                value="сохранить"
              />
            </div>
          </form>
          <div class="row-content">
            <table class="table table-bordered table-hover admin-table">
              <tbody>
                <tr v-for="work in works" :key="work.id">
                  <td>{{ work.id }}</td>
                  <td>{{ work.name_en }}</td>
                  <td>{{ work.name_ru }}</td>
                  <td>
                    <a :href="'/admin/work/' + work.id">изменить</a>
                  </td>
                  <td>
                    <a
                      href="javascript:void(0)"
                      @click="removeItem(work.id, 'works')"
                      class="link-black"
                      ><svg
                        class="bi bi-trash"
                        width="1em"
                        height="1em"
                        viewBox="0 0 16 16"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"
                        />
                        <path
                          fill-rule="evenodd"
                          d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                        /></svg
                    ></a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="admin-row">
          <div class="header-group">
            <h3>Категории</h3>
            <a
              v-if="!categoryFormActive"
              @click="categoryFormActive = !categoryFormActive"
              href="#"
              class="btn btn-primary btn-sm"
              >+</a
            >
          </div>
          <form
            @submit="categoriesFormSubmit"
            class="new-form new-form_categories"
            v-if="categoryFormActive"
          >
            <div class="form-group">
              <label>Категория на русском</label>
              <input
                type="text"
                class="form-control"
                v-model="category_name_ru"
              />
            </div>
            <div class="form-group">
              <label>Категория на английском</label>
              <input
                type="text"
                class="form-control"
                v-model="category_name_en"
              />
            </div>
            <div class="btn-group" role="group" aria-label="Basic example">
              <input
                type="submit"
                class="btn btn-primary btn-sm"
                value="сохранить"
              />
              <button
                @click="categoryFormActive = !categoryFormActive"
                type="button"
                class="btn btn-secondary btn-sm"
              >
                x
              </button>
            </div>
          </form>
          <div class="row-content">
            <table class="table table-bordered table-hover admin-table">
              <tbody>
                <tr v-for="category in categories" :key="category.id">
                  <td>{{ category.id }}</td>
                  <td>{{ category.name_en }}</td>
                  <td>{{ category.name_ru }}</td>
                  <td>
                    <a :href="'/admin/category/' + category.id">изменить</a>
                  </td>
                  <td>
                    <a
                      href="javascript:void(0)"
                      @click="removeItem(category.id, 'categories')"
                      class="link-black"
                      ><svg
                        class="bi bi-trash"
                        width="1em"
                        height="1em"
                        viewBox="0 0 16 16"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"
                        />
                        <path
                          fill-rule="evenodd"
                          d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                        /></svg
                    ></a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { Auth } from '../../Auth.js';

export default {
  name: "Admin",
  data() {
    return {
      categories: [],
      works: [],
      contacts: [],
      categoryFormActive: false,
      workFormActive: false,
      work: {},
      category_name_ru: "",
      category_name_en: "",
    };
  },
  created() {
    axios
      .get("https://velichko.co/api/categories")
      .then((response) => {
        this.categories = response.data;
        console.log("CATEGORIES", this.categories);
      })
      .catch((error) => this.setError(error, "Something went wrong"));
    axios
      .get("https://velichko.co/api/works")
      .then((response) => {
        this.works = response.data;
        console.log("WORKS", this.works);
      })
      .catch((error) => this.setError(error, "Something went wrong"));
      Auth.CreateHttpClient().get("/contacts").then(response => {
        this.contacts = response.data;
        console.log("CONTACTS", this.contacts);
      });
  },
  methods: {
    handleImage: function () {
      let file = event.target.files[0];
      //let file64 = this.getBase64(file);
      let property = event.target.name;
      let file64 = "";
      this.getBase64(file, property).then(
        data => console.log(data));
    },
    getBase64: function (file, property) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.work[property] = reader.result;
          resolve(reader.result);
        } 
        reader.onerror = error => reject(error);
      });
    },
    removeItem: function (itemid, controller) {
      if(confirm("Are u sure m8")){
        let del_adress = controller + "/" + itemid;
        Auth.CreateHttpClient().delete(del_adress).then(response => {
          console.log(response);
          if (controller=="categories") {this.categories = this.categories.filter((obj) => { return obj.id != itemid;});}
          if (controller=="works") {this.works = this.works.filter((obj) => { return obj.id != itemid;});}
          if (controller=="contacts") {this.contacts = this.contacts.filter((obj) => { return obj.id != itemid;});}
        })
        .catch((error) => console.log(error));
      }
    },
    categoriesFormSubmit: function (e) {
      e.preventDefault();
      let formError = false;
      if (this.category_name_ru.length < 2 || this.category_name_en.length < 2)
        formError = true;
      if (formError) alert("ошибка формы");
      else {
        let new_category = {
          category: {
            name_ru: this.category_name_ru,
            name_en: this.category_name_en,
          },
        };
        Auth.CreateHttpClient().post("/categories", new_category).then(response => {
          this.categories.push(response.data);
          this.category_name_ru = "";
          this.category_name_en = "";
        })
        .catch((error) => console.log(error));
      }
    },
    workFormSubmit: function (e) {
      e.preventDefault();
      let formError = false;
      if (this.work.name_ru.length < 2 || this.work.name_en.length < 2)
        formError = true;
      if (formError) alert("ошибка формы");
      else {
        let new_work = { work: this.work };
        Auth.CreateHttpClient().post("/works", new_work).then(response => {
          this.works.push(response.data);
            this.work = {};
            this.workFormActive = false;
        })
        .catch((error) => console.log(error));
      }
    },
  },
};
</script>

<style>
.admin-row {
  margin-bottom:2rem;
}
.admin-row h1 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.header-group {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.66rem;
}
.header-group h3 {
  padding-bottom: 0;
  margin-bottom: 0;
}
.admin-table {
  line-height: 32px;
}
.admin-table tr td:last-child {
  text-align: right;
}
.new-form {
  background: #eeeeee;
  padding: 20px;
}
.new-form_categories {
  display: flex;
  align-items: center;
}
.new-form .form-group {
  display: flex;
  flex: 1;
  margin-right: 20px;
  flex-direction: column;
}
.new-form_categories .form-group {
  margin-bottom: 0;
}
label {
  font-size: 14px;
  color: #666;
  margin-bottom: 0.25;
}
.new-form_categories .btn-group {
  margin-top: 28px;
}
.link-black {
  color: #333;
}
.new-form_works h4 {
  margin-bottom: 1rem;
}
.flexj {
  display: flex;
  justify-content: space-between;
}
.admin-work-file {
  height: 38px;
  font-size: 12px;
  background: #e6e6e6;
}
</style>
