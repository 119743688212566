// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Vue from 'vue';
import App from '../app.vue';
import router from '../router';
import i18n from '../i18n';

//var locale = window.location.pathname.replace(/^\/([^\/]+).*/i,'$1');

// Create VueI18n instance with options

Vue.config.productionTip = false

import {func} from '../func.js'
Vue.prototype.$func = func

document.addEventListener('DOMContentLoaded', () => {
  const el = document.body.appendChild(document.createElement('application'))
  const app = new Vue({
    el,
    router,
    i18n, 
    components: { App },
    render: h => h(App)
  })

  console.log(app)
})

