<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="admin-row">
          <h1>Сообщение {{ id }}</h1>
        </div>
        <div class="admin-row">
          <div class="contacts__description">
            <p>Имя: {{contact.name}}</p>
            <p>Почта: {{contact.mail}}</p>
            <p>Текст: {{contact.text}}</p>
            <p class="text-small">{{contact.created_at}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Auth } from '../../Auth.js';

export default {
  name: "Admin",
  data() {
    return {
      id: 0,
      contact: {},
    };
  },
  created() {
    this.id = this.$route.params.id;
    Auth.CreateHttpClient().get("/contacts/" + this.id).then(response => {
        this.contact = response.data;
      });
  },
  methods: {
    //
  },
};
</script>

<style>

</style>
