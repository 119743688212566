<template>
  <div class="container contacts">
    <div class="row">
      <div class="col-12">
        <div class="policy" v-if="$root.$i18n.locale == 'ru'">
          <h1 class="page-header">
            Политика в отношении обработки персональных данных
          </h1>
          <h3>1. Общие положения</h3>
          Настоящая политика обработки персональных данных составлена в
          соответствии с требованиями Федерального закона от 27.07.2006. №152-ФЗ
          «О персональных данных» и определяет порядок обработки персональных
          данных и меры по обеспечению безопасности персональных данных,
          предпринимаемые ИП Величко А. А. (далее – Оператор). <br />1.1.
          Оператор ставит своей важнейшей целью и условием осуществления своей
          деятельности соблюдение прав и свобод человека и гражданина при
          обработке его персональных данных, в том числе защиты прав на
          неприкосновенность частной жизни, личную и семейную тайну. <br />1.2.
          Настоящая политика Оператора в отношении обработки персональных данных
          (далее – Политика) применяется ко всей информации, которую Оператор
          может получить о посетителях веб-сайта https://velichko.co.
          <h3>2. Основные понятия, используемые в Политике</h3>
          2.1. Автоматизированная обработка персональных данных – обработка
          персональных данных с помощью средств вычислительной техники;
          <br />2.2. Блокирование персональных данных – временное прекращение
          обработки персональных данных (за исключением случаев, если обработка
          необходима для уточнения персональных данных); <br />2.3. Веб-сайт –
          совокупность графических и информационных материалов, а также программ
          для ЭВМ и баз данных, обеспечивающих их доступность в сети интернет по
          сетевому адресу https://velichko.co; <br />2.4. Информационная система
          персональных данных — совокупность содержащихся в базах данных
          персональных данных, и обеспечивающих их обработку информационных
          технологий и технических средств; <br />2.5. Обезличивание
          персональных данных — действия, в результате которых невозможно
          определить без использования дополнительной информации принадлежность
          персональных данных конкретному Пользователю или иному субъекту
          персональных данных; <br />2.6. Обработка персональных данных – любое
          действие (операция) или совокупность действий (операций), совершаемых
          с использованием средств автоматизации или без использования таких
          средств с персональными данными, включая сбор, запись, систематизацию,
          накопление, хранение, уточнение (обновление, изменение), извлечение,
          использование, передачу (распространение, предоставление, доступ),
          обезличивание, блокирование, удаление, уничтожение персональных
          данных; <br />2.7. Оператор – государственный орган, муниципальный
          орган, юридическое или физическое лицо, самостоятельно или совместно с
          другими лицами организующие и (или) осуществляющие обработку
          персональных данных, а также определяющие цели обработки персональных
          данных, состав персональных данных, подлежащих обработке, действия
          (операции), совершаемые с персональными данными; <br />2.8.
          Персональные данные – любая информация, относящаяся прямо или косвенно
          к определенному или определяемому Пользователю веб-сайта
          https://velichko.co; <br />2.9. Пользователь – любой посетитель
          веб-сайта https://velichko.co; <br />2.10. Предоставление персональных
          данных – действия, направленные на раскрытие персональных данных
          определенному лицу или определенному кругу лиц; <br />2.11.
          Распространение персональных данных – любые действия, направленные на
          раскрытие персональных данных неопределенному кругу лиц (передача
          персональных данных) или на ознакомление с персональными данными
          неограниченного круга лиц, в том числе обнародование персональных
          данных в средствах массовой информации, размещение в
          информационно-телекоммуникационных сетях или предоставление доступа к
          персональным данным каким-либо иным способом; <br />2.12.
          Трансграничная передача персональных данных – передача персональных
          данных на территорию иностранного государства органу власти
          иностранного государства, иностранному физическому или иностранному
          юридическому лицу; 2.13. Уничтожение персональных данных – любые
          действия, в результате которых персональные данные уничтожаются
          безвозвратно с невозможностью дальнейшего восстановления содержания
          персональных данных в информационной системе персональных данных и
          (или) уничтожаются материальные носители персональных данных.
          <h3>
            3. Оператор может обрабатывать следующие персональные данные
            Пользователя
          </h3>
          3.1. Фамилия, имя, отчество; <br />3.2. Электронный адрес; <br />3.3.
          Также на сайте происходит сбор и обработка обезличенных данных о
          посетителях (в т.ч. файлов «cookie») с помощью сервисов
          интернет-статистики (Яндекс Метрика и Гугл Аналитика и других).
          <br />3.4. Вышеперечисленные данные далее по тексту Политики
          объединены общим понятием Персональные данные.

          <h3>4. Цели обработки персональных данных</h3>
          4.1. Цель обработки персональных данных Пользователя — заключение,
          исполнение и прекращение гражданско-правовых договоров; предоставление
          доступа Пользователю к сервисам, информации и/или материалам,
          содержащимся на веб-сайте https://velichko.co; уточнение деталей
          заказа. <br />4.2. Также Оператор имеет право направлять Пользователю
          уведомления о новых продуктах и услугах, специальных предложениях и
          различных событиях. Пользователь всегда может отказаться от получения
          информационных сообщений, направив Оператору письмо на адрес
          электронной почты alexander@velichko.co с пометкой «Отказ от
          уведомлений о новых продуктах и услугах и специальных предложениях».
          <br />4.3. Обезличенные данные Пользователей, собираемые с помощью
          сервисов интернет-статистики, служат для сбора информации о действиях
          Пользователей на сайте, улучшения качества сайта и его содержания.
          <h3>5. Правовые основания обработки персональных данных</h3>
          5.1. Оператор обрабатывает персональные данные Пользователя только в
          случае их заполнения и/или отправки Пользователем самостоятельно через
          специальные формы, расположенные на сайте https://velichko.co.
          Заполняя соответствующие формы и/или отправляя свои персональные
          данные Оператору, Пользователь выражает свое согласие с данной
          Политикой. <br />5.2. Оператор обрабатывает обезличенные данные о
          Пользователе в случае, если это разрешено в настройках браузера
          Пользователя (включено сохранение файлов «cookie» и использование
          технологии JavaScript).
          <h3>
            6. Порядок сбора, хранения, передачи и других видов обработки
            персональных данных
          </h3>
          Безопасность персональных данных, которые обрабатываются Оператором,
          обеспечивается путем реализации правовых, организационных и
          технических мер, необходимых для выполнения в полном объеме требований
          действующего законодательства в области защиты персональных данных.
          6.1. Оператор обеспечивает сохранность персональных данных и принимает
          все возможные меры, исключающие доступ к персональным данным
          неуполномоченных лиц. <br />6.2. Персональные данные Пользователя
          никогда, ни при каких условиях не будут переданы третьим лицам, за
          исключением случаев, связанных с исполнением действующего
          законодательства. <br />6.3. В случае выявления неточностей в
          персональных данных, Пользователь может актуализировать их
          самостоятельно, путем направления Оператору уведомление на адрес
          электронной почты Оператора alexander@velichko.co с пометкой
          «Актуализация персональных данных». <br />6.4. Срок обработки
          персональных данных является неограниченным. Пользователь может в
          любой момент отозвать свое согласие на обработку персональных данных,
          направив Оператору уведомление посредством электронной почты на
          электронный адрес Оператора alexander@velichko.co с пометкой «Отзыв
          согласия на обработку персональных данных».
          <h3>7. Трансграничная передача персональных данных</h3>
          7.1. Оператор до начала осуществления трансграничной передачи
          персональных данных обязан убедиться в том, что иностранным
          государством, на территорию которого предполагается осуществлять
          передачу персональных данных, обеспечивается надежная защита прав
          субъектов персональных данных. <br />7.2. Трансграничная передача
          персональных данных на территории иностранных государств, не
          отвечающих вышеуказанным требованиям, может осуществляться только в
          случае наличия согласия в письменной форме субъекта персональных
          данных на трансграничную передачу его персональных данных и/или
          исполнения договора, стороной которого является субъект персональных
          данных.
          <h3>8. Заключительные положения</h3>
          8.1. Пользователь может получить любые разъяснения по интересующим
          вопросам, касающимся обработки его персональных данных, обратившись к
          Оператору с помощью электронной почты alexander@velichko.co.
          <br />8.2. В данном документе будут отражены любые изменения политики
          обработки персональных данных Оператором. Политика действует бессрочно
          до замены ее новой версией. <br />8.3. Актуальная версия Политики в
          свободном доступе расположена в сети Интернет по адресу
          https://velichko.co/ru/privacy.
        </div>
        <div class="policy" v-else>
            <h1 class="page-header">
            Privacy Policy
          </h1>
          <p>
            It is IP Velichko A. A.'s policy to respect your privacy regarding
            any information we may collect while operating our website. This
            Privacy Policy applies to
            <a href="https://velichko.co">https://velichko.co</a> (hereinafter,
            "us", "we", or "https://velichko.co"). We respect your privacy and
            are committed to protecting personally identifiable information you
            may provide us through the Website. We have adopted this privacy
            policy ("Privacy Policy") to explain what information may be
            collected on our Website, how we use this information, and under
            what circumstances we may disclose the information to third parties.
            This Privacy Policy applies only to information we collect through
            the Website and does not apply to our collection of information from
            other sources.
          </p>
          <p>
            This Privacy Policy, together with the Terms and conditions posted
            on our Website, set forth the general rules and policies governing
            your use of our Website. Depending on your activities when visiting
            our Website, you may be required to agree to additional terms and
            conditions.
          </p>
          <h3>Website Visitors</h3>
          <p>
            Like most website operators, IP Velichko A. A. collects
            non-personally-identifying information of the sort that web browsers
            and servers typically make available, such as the browser type,
            language preference, referring site, and the date and time of each
            visitor request. IP Velichko A. A.'s purpose in collecting
            non-personally identifying information is to better understand how
            IP Velichko A. A.'s visitors use its website. From time to time, IP
            Velichko A. A. may release non-personally-identifying information in
            the aggregate, e.g., by publishing a report on trends in the usage
            of its website.
          </p>
          <p>
            IP Velichko A. A. also collects potentially personally-identifying
            information like Internet Protocol (IP) addresses for logged in
            users and for users leaving comments on https://velichko.co blog
            posts. IP Velichko A. A. only discloses logged in user and commenter
            IP addresses under the same circumstances that it uses and discloses
            personally-identifying information as described below.
          </p>
          <h3>Gathering of Personally-Identifying Information</h3>
          <p>
            Certain visitors to IP Velichko A. A.'s websites choose to interact
            with IP Velichko A. A. in ways that require IP Velichko A. A. to
            gather personally-identifying information. The amount and type of
            information that IP Velichko A. A. gathers depends on the nature of
            the interaction. For example, we ask visitors who sign up for a blog
            at https://velichko.co to provide a username and email address.
          </p>
          <h3>Security</h3>
          <p>
            The security of your Personal Information is important to us, but
            remember that no method of transmission over the Internet, or method
            of electronic storage is 100% secure. While we strive to use
            commercially acceptable means to protect your Personal Information,
            we cannot guarantee its absolute security.
          </p>
          <h3>Cookies</h3>
          <p>
            To enrich and perfect your online experience, IP Velichko A. A. uses
            "Cookies", similar technologies and services provided by others to
            display personalized content, appropriate advertising and store your
            preferences on your computer.
          </p>
          <p>
            A cookie is a string of information that a website stores on a
            visitor's computer, and that the visitor's browser provides to the
            website each time the visitor returns. IP Velichko A. A. uses
            cookies to help IP Velichko A. A. identify and track visitors, their
            usage of https://velichko.co, and their website access preferences.
            IP Velichko A. A. visitors who do not wish to have cookies placed on
            their computers should set their browsers to refuse cookies before
            using IP Velichko A. A.'s websites, with the drawback that certain
            features of IP Velichko A. A.'s websites may not function properly
            without the aid of cookies.
          </p>
          <p>
            By continuing to navigate our website without changing your cookie
            settings, you hereby acknowledge and agree to IP Velichko A. A.'s
            use of cookies.
          </p>
          <h3>Privacy Policy Changes</h3>
          <p>
            Although most changes are likely to be minor, IP Velichko A. A. may
            change its Privacy Policy from time to time, and in IP Velichko A.
            A.'s sole discretion. IP Velichko A. A. encourages visitors to
            frequently check this page for any changes to its Privacy Policy.
            Your continued use of this site after any change in this Privacy
            Policy will constitute your acceptance of such change.
          </p>
          <h3>Credit & Contact Information</h3>
          <p>
            This privacy policy was created at
            <a
              style="color: inherit; text-decoration: none;"
              href="https://termsandconditionstemplate.com/privacy-policy-generator/"
              title="Privacy policy template generator"
              target="_blank"
              >termsandconditionstemplate.com</a
            >. If you have any questions about this Privacy Policy, please
            contact us via <a href="mailto:st2dat@gmail.com">email</a> or
            <a href="tel:79048244223">phone</a>.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Policy',
  metaInfo() {
    return {
    title: this.$t("translation.title_policy")
    }
  },
}



</script>
