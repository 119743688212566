import Vue from 'vue'
import Router from 'vue-router'
import Index from '../components/Index'
import Contacts from '../components/Contacts'
import Services from '../components/Services'
import Login from '../components/Login'
import Works from '../components/Works'
import Work from '../components/Work'
import Policy from '../components/Policy'
import NotFound from '../components/NotFound'
import Admin from "../components/Admin/Admin" 
import Rview from "./rview" 
import editCategory from "../components/Admin/editCategory"
import editWork from "../components/Admin/editWork"
import showContact from "../components/Admin/showContact"
import { Auth } from '../Auth.js';
import Meta from 'vue-meta';
import VueYandexMetrika from 'vue-yandex-metrika'
import i18n from '../i18n';

Vue.use(Meta);
Vue.use(Router);


import { SUPPORTED_LOCALES } from '../constants/locales';

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [{
    path: `/:locale${getLocaleRegex()}?`,
    component: Rview,
    children:  [
      {
        path: '',
        name: 'Index',
        component: Index
      },
      {
        path: 'contacts',
        name: 'Contacts',
        component: Contacts
      },
      {
        path: 'services',
        name: 'Services',
        component: Services
      },
      {
        path: 'works',
        name: 'Works',
        component: Works
      },
      {
        path: 'works/:id',
        name: 'Work',
        component: Work
      },
      {
        path: 'policy',
        name: 'Policy',
        component: Policy
      },
      {
        path: 'login',
        name: 'Login',
        component: Login
      },
      {
        path: 'admin',
        name: 'Admin',
        component: Admin
      },
      {
        path: 'admin/category/:id',
        name: 'editCategory',
        component: editCategory
      },
      {
        path: 'admin/work/:id',
        name: 'editWork',
        component: editWork
      },
      {
        path: 'admin/contacts/:id',
        name: 'showContact',
        component: showContact
      },
    ]
  },
  { path: "*", component: NotFound }
  ]
});

function getLocale(locale = 'en') {
  return SUPPORTED_LOCALES.find(loc => loc.code === locale);
}

function getLocaleRegex() {
  let reg = '';
  SUPPORTED_LOCALES.forEach((locale, index) => {
    reg = `${reg}${locale.code}${index !== SUPPORTED_LOCALES.length - 1 ? '|' : ''}`;
  });
  return `(${reg})`;
}

Vue.use(VueYandexMetrika, {
  id: 65357026,
  router: router,
  env: process.env.NODE_ENV
})

router.beforeEach((to, from, next) => {
  const deniedPagenames = ['Admin', 'editCategory', 'editWork', 'showContact']
  const authRequired = deniedPagenames.includes(to.name);
  const currentUser = Auth.GetCurrentUser();
  const locale = getLocale(to.params.locale);
  i18n.locale = locale.code;
  if (authRequired && (!currentUser || currentUser.id <=0)) {
    return next('/login');
  }
  next();
});

export default router;