<template>
  <div class="container">
    <div class="row">
      <div class="col-6">
        <div class="admin-row">
          <h1>Категория {{ id }}</h1>
        </div>
        <div class="admin-row">
          <form @submit="categoryUpdate" class="editForm">
            <div class="form-group">
              <label>Категория на русском</label>
              <input
                type="text"
                class="form-control"
                v-model="category.name_ru"
              />
            </div>
            <div class="form-group">
              <label>Категория на английском</label>
              <input
                type="text"
                class="form-control"
                v-model="category.name_en"
              />
            </div>
            <div class="form-buttons">
              <input type="submit" class="btn btn-primary" value="save" />
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Auth } from '../../Auth.js';

export default {
  name: "Admin",
  data() {
    return {
      id: 0,
      category: {},
    };
  },
  created() {
    this.id = this.$route.params.id;
    Auth.CreateHttpClient().get("/categories/" + this.id).then(response => {
        this.category = response.data;
        console.log("CATEGORIES", this.category);
      })
      .catch((error) => this.setError(error, "Something went wrong"));      
  },
  methods: {
    categoryUpdate: function (e) {
      e.preventDefault();
      let formError = false;
      if (this.category.name_ru.length < 2 || this.category.name_en.length < 2)
        formError = true;
      if (formError) alert("ошибка формы");
      else {
        let updated_category = {
          category: {
            id: this.id,
            name_ru: this.category.name_ru,
            name_en: this.category.name_en,
          },
        };
        Auth.CreateHttpClient().put("/categories/" + this.id, updated_category).then(response => {
            console.log(response.data);
            this.$router.go(-1);
          })
          .catch((error) => this.setError(error, "Something went wrong"));
      }
    },
  },
};
</script>

<style>
.editForm .form-group {
  margin-right: 0;
}
.editForm .form-buttons {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
</style>
