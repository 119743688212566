<template>
  <div class="container contacts">
    <div class="row">
      <div class="col-12">
        <h1 class="page-header">
          {{$t("translation.header_contacts")}}
        </h1>
      </div>
    </div>
    <div class="row contacts__content">
      <div class="col-md-6">
        <form @submit="checkForm" class="contacts__form">
          <div v-if="errors.length" class="alert alert-danger" role="alert">
            {{$t("translation.form_errors")}}
            <ul>
              <li v-for="error in errors" v-bind:key="error.id">{{ error }}</li>
            </ul>
          </div>
          <div v-if="formSent"  class="alert alert-success" role="alert">
            {{$t("translation.form_success")}}
          </div>
          <div class="form-group">
            <label>{{$t("translation.form_name")}}</label>
            <input :disabled="formSent" type="text" class="form-control" v-model="contact.name" name="name" />
          </div>
          <div class="form-group">
            <label>{{$t("translation.form_mail")}}</label>
            <input :disabled="formSent" type="text" class="form-control" v-model="contact.mail" name="email" />
          </div>
          <div class="form-group">
            <label>{{$t("translation.form_text")}}</label>
            <textarea :disabled="formSent" type="text" class="form-control" v-model="contact.text" name="text"></textarea>
          </div>
          <div class="submit-block">
            <p>
              {{$t("translation.privacy_policy_text")}}
              <router-link :to="$func.getLink('policy')">{{$t("translation.privacy_policy_link")}}</router-link>.
            </p>
            <input :disabled="formSent" type="submit" class="contacts__button" :value="$t('translation.form_button')" />
          </div>
        </form>
      </div>
      <div class="col-md-6">
        <div class="contacts__description">
          <p>{{$t("translation.form_description_1")}}</p>
          <p>{{$t("translation.form_description_2")}}</p>
          <p>{{$t("translation.form_description_3")}} <a href="mailto:alexander@velichko.co">alexander@velichko.co</a>.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Contacts",
  data() {
    return {
      errors: [],
      contact: {name: "", text: "", mail: ""},
      formSent: false,
      mailRegex: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    };
  },
  metaInfo() {
    return {
    title: this.$t("translation.title_contacts"),
    meta: [
        { name: 'description', content: this.$t("translation.meta_contacts") },
    ],
    }
  },
  methods: {
    checkForm: function(e) {
      e.preventDefault();
      this.errors = [];
      if (this.contact.name.length < 2) this.errors.push(this.$t('translation.form_error_name'));
      if (!this.mailRegex.test(this.contact.mail.toLowerCase())) this.errors.push(this.$t('translation.form_error_mail'));
      if (this.contact.text.length < 5) this.errors.push(this.$t('translation.form_error_text'));
      if (this.errors.length==0) {
        let new_contact = { contact: this.contact };
        axios
          .post("https://velichko.co/api/contacts", new_contact)
          .then((response) => {
            this.formSent = true;
            //axios.post('http://amosenko.ru/m.php', {
            //  text: this.contact.text,
            //  name: this.contact.name,
            //  email: this.contact.mail
            //});
          })
          .catch((error) => console.log(error));
      }
    }
  }
};
</script>
