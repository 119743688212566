<template>
  <section class="intro">
    <div class="container">
      <div class="row intro__row">
        <div class="col-12 col-md-7">
        <h1 class="intro__head">{{$t("translation.hello_header")}}</h1>
        <p class="intro__description">
          {{$t("translation.hello_text")}}
        </p>
        <div class="intro__links">
          <router-link :to="$func.getLink('works')" class="link intro__link">{{$t("translation.button_works")}}</router-link>
          <router-link :to="$func.getLink('contacts')" class="button intro__button">{{$t("translation.button_contacts")}}</router-link>
        </div>
      </div>
      <div class="col-12 col-md-5">
        <div class="rocket-image">
          <img v-bind:src="rocketImage">
        </div>
      </div>
      </div>
    </div>
  </section>
</template>

<script>
import rocketImage from 'images/rocket.png';

export default {
  name: "Index",
  metaInfo() {
    return {
    title: this.$t("translation.title_index"),
    meta: [
        { name: 'description', content: this.$t("translation.hello_text") },
    ],
    }
  },
  data() {
    return {
      rocketImage
    };
  }
};
</script>
