<template>
  <div class="container services">
    <div class="row">
      <div class="col-12">
        <h1 class="page-header">
          {{$t("translation.header_services")}}
        </h1>
        <div class="services__subheader">
          <p>{{$t("translation.services_description_1")}}</p>
          <p>{{$t("translation.services_description_2")}}</p>
        </div>
      </div>
    </div>
    <div class="row services__row">
      <div class="col-12">
        <div class="service service_main">
          <div class="service__image">
            <img v-bind:src="uiuxImage">
          </div>
          <div class="service__content">
            <div class="service__clarification">
              {{$t("translation.services_the_main_service")}}
            </div>
            <h2>
              {{$t("translation.service_1_header")}}
            </h2>
            <div class="service_main__text">
              <p>{{$t("translation.service_1_description_1")}}</p>
              <p>{{$t("translation.service_1_description_2")}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row services__row row_sheight">
      <div class="col-12 col-md-6">
        <div class="service service_additional">
          <h3>{{$t("translation.service_2_header")}}</h3>
          <p class="first-paragraph">{{$t("translation.service_2_description")}}</p>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="service service_additional">
          <h3>{{$t("translation.service_3_header")}}</h3>
          <p class="first-paragraph">{{$t("translation.service_3_description")}}</p>
        </div>
      </div>
    </div>
    <div class="row services__row">
      <div class="col-12">
        <div class="service service_support">
          <h3>{{$t("translation.service_4_header")}}</h3>
          <p class="first-paragraph">{{$t("translation.service_4_description")}}</p>
          <p class="parapgraph_got-quesitons">
            {{$t("translation.service_4_questions")}}
            <a :href="$func.getLink('contacts')">{{$t("translation.service_4_questions_form")}}</a>,
            <a href="http://t.me/velichko_alexander">{{$t("translation.service_4_questions_telegram")}}</a>,
            {{$t("translation.service_4_questions_or")}}
            <a href="mailto:alexander@velichko.co">{{$t("translation.service_4_questions_email")}}</a>.
          </p>
        </div>
      </div>
      </div>
    </div>
</template>


: 'You can get in contact with me by the telegram or email',
        service_4_questions_form: 'form',
        service_4_questions_telegram: 'telegram',
        service_4_questions_or: 'or',
        service_4_questions_email: 'email',

<script>
import uiuxImage from 'images/uiux.svg';

export default {
  name: "Index",
  metaInfo: {
      title: 'My services',
      meta: [
        { vmid: 'description', property: 'description', content: 'Vue App' },
      ],
  },
  metaInfo() {
    return {
    title: this.$t("translation.title_services"),
    meta: [
        { name: 'description', content: this.$t("translation.meta_services") },
    ],
    }
  },
  data() {
    return {
      uiuxImage
    };
  }
};
</script>
