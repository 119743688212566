<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="admin-row">
          <h1>Работа {{ id }}</h1>
        </div>
        <div class="admin-row">
          <form @submit="workUpdate" class="new-form">
            <div class="flexj">
              <div class="form-group">
                <label>Название работы на русском</label>
                <input type="text" class="form-control" v-model="work.name_ru" />
              </div>
              <div class="form-group">
                <label>Название работы на английском</label>
                <input type="text" class="form-control" v-model="work.name_en" />
              </div>
            </div>
            <div class="flexj">
              <div class="form-group">
                <label>Описание на русском</label>
                <textarea
                  type="text"
                  class="form-control"
                  v-model="work.description_ru"
                ></textarea>
              </div>
              <div class="form-group">
                <label>Описание на английском</label>
                <textarea
                  type="text"
                  class="form-control"
                  v-model="work.description_en"
                ></textarea>
              </div>
            </div>
            <div class="form-group">
              <label>Категория</label>
              <select
                id="artist_update"
                class="form-control"
                v-model="work.category_id"
              >
                <option
                  :value="category.id"
                  v-for="(category, index) in categories"
                  :key="category.id"
                  v-bind:selected="index === 0"
                  >{{ category.name_ru }}</option
                >
              </select>
            </div>
            <div class="form-group">
              <label>Ссылка</label>
              <input type="text" class="form-control" v-model="work.link" />
            </div>
            <div class="flexj">
              <div class="form-group">
              <label>Фон</label>
              <input
                type="file"
                class="form-control admin-work-file"
                accept="image/*"
                name="background_img"
                @change="handleImage"
              />
            </div>
            <div class="form-group">
              <label>Картинка</label>
              <input
                type="file"
                class="form-control admin-work-file"
                accept="image/*"
                name="main_img"
                @change="handleImage"
              />
            </div>
            <div class="form-group">
              <label>Миниатюра</label>
              <input
                type="file"
                class="form-control admin-work-file"
                accept="image/*"
                name="thumb_img"
                @change="handleImage"
              />
            </div>
            </div>
          <div class="flexj">
              <div class="form-group">
                <label>Цвет фона</label>
                <input
                  type="color"
                  class="form-control"
                  v-model="work.background_color"
                />
              </div>
              <div class="form-group">
                <label>Цвет заголовка</label>
                <input
                  type="color"
                  class="form-control"
                  v-model="work.header_color"
                />
              </div>
              <div class="form-group">
                <label>Цвет подзаголовка</label>
                <input
                  type="color"
                  class="form-control"
                  v-model="work.description_color"
                />
              </div>
            </div>
            <div class="btn-group" role="group" aria-label="Basic example">
              <input
                type="submit"
                class="btn btn-primary btn-sm"
                value="сохранить"
              />
            </div>
            <div class="form-buttons">
              <input type="submit" class="btn btn-primary" value="save" />
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Auth } from '../../Auth.js';

export default {
  name: "Admin",
  data() {
    return {
      id: 0,
      categories: [],
      work: {},
    };
  },
  created() {
    this.id = this.$route.params.id;
    Auth.CreateHttpClient().get("/categories").then(response => {
      this.categories = response.data;
    });
    Auth.CreateHttpClient().get("/works/" + this.id).then(response => {
      this.work = response.data;
    });
  },
  methods: {
    workUpdate: function (e) {
      e.preventDefault();
      let formError = false;
      if (this.work.name_ru.length < 2 || this.work.name_en.length < 2)
        formError = true;
      if (formError) alert("ошибка формы");
      else {
        let updated_work = { work: this.work };
        Auth.CreateHttpClient().put("/works/" + this.id, updated_work).then(response => {
          console.log(response.data);
            this.$router.go(-1);
        })
        .catch((error) => console.log(error));;
      }
    },
    handleImage: function () {
      let file = event.target.files[0];
      let property = event.target.name;
      let file64 = "";
      this.getBase64(file, property).then(
        data => console.log(data));
    },
    getBase64: function (file, property) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.work[property] = reader.result;
          resolve(reader.result);
        } 
        reader.onerror = error => reject(error);
      });
    },
  },
};
</script>

<style>
.editForm .form-group {
  margin-right: 0;
}
.editForm .form-buttons {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
</style>
