<template>
  <div v-if="isWorkLoaded" class="work" v-bind:style="{'background-image': 'url(' + work.background_img.url + ')', 'background-size': 'cover'}">
    <div class="container">
      <div class="row">
        <div class="col-12">
            <div class="work__header"><h1 v-bind:style="{'color': work.header_color}">{{ work[row_name] }}</h1>
          <p class="work__description" v-bind:style="{'color': work.description_color}">
            {{ work[row_description] }}
          </p>
          <a class="work__link" v-bind:href="work.link" v-bind:style="{'color': work.header_color}">{{ shortened_link }}</a></div>
          <img class="work__image" v-bind:src="work.main_img.url" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Auth } from "../Auth.js";
export default {
  name: "Admin",
  data() {
    return {
      id: 0,
      isWorkLoaded: false,
      work: {},
    };
  },
  computed: {
    row_name: function () {return "name_" + this.$root.$i18n.locale},
    row_description: function () {return "description_" + this.$root.$i18n.locale},
    shortened_link: function () {
      if (this.work.link) return this.work.link.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "").split('/')[0];
      else return null;
    }
  },
  metaInfo() {
    return {
    title: this.work.name_ru + ' | Alexander Velichko',
    meta: [
        { vmid: 'description', property: 'description', content: 'Vue App' },
    ],
    }
  },
  created() {
    this.id = this.$route.params.id;
    console.log(this.id);
    Auth.CreateHttpClient()
      .get("/works/" + this.id)
      .then((response) => {
        this.work = response.data;
        this.isWorkLoaded = true;
      });
  },
  methods: {
    exampel: function (e) {
      e.preventDefault();
    },
  },
};
</script>
